import _ from "lodash";
import React, { PureComponent, useMemo } from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { Input } from "../../common/Input";
import OwnAddressSelector from "../../common/OwnAddressSelector";
import {
  CO_TYPES,
  T_AIRFREIGHT,
  T_CONTRACT,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import { formatCurrency, formatDate, formatUnit } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import DateInput from "../../common/DateInput";
import Tooltip from "../../common/Tooltip";
import { AlternativePrice, getDeliveryTimeLabel } from "../../../utils/customerOrderUtils";
import { Address, CustomerPriceInfo } from "../../../model/commonTypes";
import { Textarea } from "../../common/Textarea";
import CustomerOrderFileUpload from "./CustomerOrderFileUpload";
import { resolveFilePath } from "../../../utils/fileUtils";
import { D_MASTERSPECIFICATION, D_SUPPLIERSPECIFICATION } from "../../../utils/commodityUtils";
import { MatchingIncomingOrderableStock } from "../../common/CustomTypes";
import { CC_PERIOD_OPTIONS, ContractRequestData } from "../../../utils/customerContractUtils";
import CreateAddressModal from "../../customers/customer/CreateAddressModal";
import { BatchFile } from "../../../model/batch.types";
import { AIR_MAX_ORDER_QUANTITY, DateType, SEA_MIN_ORDER_QUANTITY } from "../../../utils/orderUtils";
import { getCW } from "../../../utils/dateUtils";
import { getRelevantSystemNotifications } from "../../../utils/systemNotificationUtils";
import { SN_TYPE } from "../../../model/systemNotification.types";
import { AddressSelectOption } from "../../../utils/addressUtils";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";
import CalendarWeekSelector from "../../common/CalendarWeekSelector";
import { CommodityExtended, UploadedFileExtended } from "../../../model/commodity.types";
import { Supplier } from "../../../model/supplier.types";
import { CustomerSupplier } from "../../../model/customer/customerSupplier.types";
import { ArticleExtended, isAnyFinishedProduct } from "../../../utils/productArticleUtils";
import { C_LABEL_DESIGN_OPTIONS } from "../../../utils/companyUtils";

interface CreateCustomerOrderSettingsProps {
  article?: ArticleExtended;
  articleState?: ArticleExtended;
  priceInfo: CustomerPriceInfo | null;
  alternativePrices?: Array<AlternativePrice>;
  amount: number;
  shippingAddress?: AddressSelectOption;
  targetDate: Date;
  targetDateType?: DateType;
  earliestDeliveryDate: Date;
  reference: string;
  note: string;
  method: CO_TYPES;
  errors: Array<string>;
  availableStock: number;
  contractRequestData: ContractRequestData;
  incomingOrderableStock: MatchingIncomingOrderableStock | undefined;
  earliestExpiry?: Date;
  context: DataContextCustomerType | DataContextAnonymousType;
  suitableAmount: { upper: number; lower: number } | null | undefined;
  files?: Array<File>;
  supplier?: SelectOption<Supplier>;
  labelDesign: SelectOption;
  loading: boolean;
  onChangeContractData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeContractPeriod: (e: SelectOption) => void;
  onSelectSupplier: (e: SelectOption<Supplier>) => void;
  onSelectLabelDesign: (e: SelectOption) => void;
  onFiles: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCommodity: (e: SelectOption<CommodityExtended>) => void;
  onChangeAddress: (e: AddressSelectOption) => void;
  onBlurAmount: (e: React.ChangeEvent<HTMLInputElement> | number) => void;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeMethod: (method: CO_TYPES) => void;
  onChangeTargetDate: (e: React.ChangeEvent<HTMLInputElement> | Date) => void;
  onChangeTargetDateType: (newType: DateType) => void;
  onContinue: () => void;
  onRequestPriceUpdate: () => void;
  onAddNewAddress: (address: Address) => void;
}

class CreateCustomerOrderSettings extends PureComponent<CreateCustomerOrderSettingsProps> {
  handleClickOnBlur = () => {
    (this.props.context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  getAlternativePrice = (method: string): [string, number] => {
    const { alternativePrices } = this.props;
    const price = alternativePrices?.find((price) => price.method === method)?.priceInfo?.unitPrice;
    if (price && isFinite(price)) return [formatCurrency(price, CUSTOMER_BASE_CURRENCY), price];
    return ["", 0];
  };

  render() {
    const {
      article,
      articleState,
      priceInfo,
      amount,
      targetDate,
      targetDateType,
      earliestDeliveryDate,
      reference,
      shippingAddress,
      availableStock,
      earliestExpiry,
      method,
      errors,
      context,
      suitableAmount,
      files,
      note,
      supplier,
      labelDesign,
      contractRequestData,
      loading,
      onChangeContractData,
      onChangeContractPeriod,
      onSelectSupplier,
      onSelectLabelDesign,
      onFiles,
      onChangeCommodity,
      onBlurAmount,
      onChangeMethod,
      onChangeInput,
      onChangeAddress,
      onContinue,
      onChangeTargetDate,
      onChangeTargetDateType,
      onRequestPriceUpdate,
      onAddNewAddress,
      incomingOrderableStock,
    } = this.props;
    const stockAvailable = amount <= availableStock || incomingOrderableStock;
    const useIncomingStock =
      method === T_WAREHOUSE &&
      amount > availableStock &&
      incomingOrderableStock &&
      incomingOrderableStock.availableAmount >= amount;
    const noPrice = !priceInfo || !isFinite(priceInfo.unitPrice) || priceInfo.unitPrice === 0;
    const noWarehousePrice = noPrice || (method === T_WAREHOUSE && !stockAvailable && !useIncomingStock);
    const airFreightDisabled = amount > AIR_MAX_ORDER_QUANTITY;
    const seaFreightDisabled = amount < SEA_MIN_ORDER_QUANTITY;
    let [warehouseTabPrice, warehouseTabPriceAsNumber]: [warehouseTabPrice: string, warehouseTabPriceAsNumber: number] =
      this.getAlternativePrice(T_WAREHOUSE);
    if (!warehouseTabPrice) {
      warehouseTabPrice = useIncomingStock
        ? formatCurrency(incomingOrderableStock.price, incomingOrderableStock.currency)
        : "";
      warehouseTabPriceAsNumber = useIncomingStock ? incomingOrderableStock.price : 0;
    }

    const relevantSystemNotifications = getRelevantSystemNotifications(SN_TYPE.SN_T_ORDERMASK, context, {
      transport: method,
    });

    const isAC = isAnonymousContext(context);
    const isFP = article && isAnyFinishedProduct(article);

    return (
      <>
        {!article && (
          <div className="mb-4">
            <label className="align-items-center fs-6 fw-bold mb-2">
              <span className="required">Commodity</span>
            </label>
            <CustomSelect
              options={context.commodity.map((c) => {
                return { label: c.title.en, value: c._id.toString(), object: c };
              })}
              onChange={onChangeCommodity}
              value={
                articleState
                  ? {
                      label: articleState.title.en,
                      value: articleState._id.toString(),
                    }
                  : undefined
              }
            />
          </div>
        )}
        {articleState && (
          <>
            <div className="row">
              <div className={method === T_CONTRACT ? "col-12" : "col-6"}>
                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span className="required">Amount</span>
                </label>
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type="number"
                    name="amount"
                    min={0}
                    value={amount}
                    onBlur={onBlurAmount}
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {articleState.unit}
                    </div>
                  </div>
                </div>
                <div className="text-danger float-right " style={{ height: "1rem" }}>
                  {suitableAmount && `Recommended amount: ${suitableAmount.upper}${articleState.unit}`}
                </div>
              </div>
              <div className={method === T_CONTRACT ? "d-none" : "col-6"}>
                <label className="required fs-6 fw-bold mb-2">Target Date</label>
                <div>
                  {targetDateType === DateType.CW ? (
                    <CalendarWeekSelector
                      inputClassNames={
                        targetDate < earliestDeliveryDate
                          ? method === T_SPECIALREQUEST
                            ? "custom-form-control-warning"
                            : "custom-form-control-invalid"
                          : ""
                      }
                      value={targetDate}
                      min={new Date()}
                      onSelectCalendarWeek={onChangeTargetDate}
                    />
                  ) : (
                    <DateInput
                      classes={
                        "form-control custom-form-control " +
                        (targetDate < earliestDeliveryDate
                          ? method === T_SPECIALREQUEST
                            ? "custom-form-control-warning"
                            : "custom-form-control-invalid"
                          : "")
                      }
                      value={targetDate}
                      onBlur={onChangeTargetDate}
                      name={"targetDate"}
                      min={new Date()}
                    />
                  )}
                </div>
                <div className="text-danger float-right " style={{ height: "1rem" }}>
                  {targetDate < earliestDeliveryDate &&
                    `Earliest arrival on ${earliestDeliveryDate.toLocaleDateString()}`}{" "}
                </div>
              </div>
            </div>
            <div className={method === T_CONTRACT ? "d-none" : "row mb-4"}>
              <div className="col-6" />
              <div className="col-6 d-flex flex-row">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
                  <input
                    className="form-check-input position-static"
                    checked={targetDateType === DateType.CW}
                    type="checkbox"
                    onChange={() => onChangeTargetDateType(DateType.CW)}
                  />
                  <label className="form-check-label fs-6">Calendar Week</label>
                </div>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input position-static"
                    checked={targetDateType === DateType.FIX}
                    type="checkbox"
                    disabled={method === T_SEAFREIGHT}
                    onChange={() => onChangeTargetDateType(DateType.FIX)}
                  />
                  <label className={`form-check-label fs-6 ${method === T_SEAFREIGHT ? "text-muted" : ""}`}>
                    Fixed Date
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span className="required">Destination Address</span>
                </label>
                <div className="row">
                  <div className="col-10 pr-0">
                    {isAC ? (
                      <CustomSelect options={[]} matchFormControl={true} placeholder="Address..." disabled />
                    ) : (
                      <OwnAddressSelector
                        context={context}
                        onChange={onChangeAddress}
                        value={shippingAddress}
                        matchFormControl={true}
                        placeholder="Shipping Address..."
                      />
                    )}
                  </div>
                  <div className="col-2 text-right">
                    {isAC ? (
                      <button className="btn btn-light btn-sm disabled" disabled>
                        +
                      </button>
                    ) : (
                      <CreateAddressModal onHide={onAddNewAddress} context={context} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span>Order Reference</span>
                </label>
                <Input
                  type="text"
                  className="form-control custom-form-control"
                  placeholder="Your internal order reference..."
                  name="reference"
                  value={reference}
                  onBlur={onChangeInput}
                />
              </div>
            </div>
            {method !== T_SPECIALREQUEST && (
              <CreateCustomerOrderSupplierSelection
                amount={amount}
                article={articleState}
                context={context}
                supplier={supplier}
                labelDesign={labelDesign}
                onSelectSupplier={onSelectSupplier}
                onSelectLabelDesign={onSelectLabelDesign}
                method={method}
              />
            )}
            <div className="mb-6">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span className="required">Ordering Type</span>
              </label>
              <div>
                <div className="btn-group w-100">
                  <label
                    style={{ opacity: isFP ? 0.7 : 1 }}
                    className={"btn btn-light " + (method === T_WAREHOUSE ? "active" : isFP ? "no-hover disabled" : "")}
                    onClick={isFP ? undefined : () => onChangeMethod(T_WAREHOUSE)}
                  >
                    Warehouse
                    <small className="text-success ml-2">
                      {!noWarehousePrice && warehouseTabPrice}
                      {!stockAvailable && availableStock > 0 && (
                        <Tooltip
                          tooltipText={`Only ${formatUnit(
                            availableStock,
                            articleState?.unit || "kg"
                          )} currently available`}
                          tooltipClass={"custom-tooltip mb-8"}
                        >
                          <i className="fa fa-exclamation-circle text-muted opacity-04 ml-1" />
                        </Tooltip>
                      )}
                    </small>
                  </label>
                  <label
                    style={{ opacity: isFP ? 0.7 : 1 }}
                    className={
                      "btn btn-light " + (method === T_SEAFREIGHT ? "active" : isFP ? "no-hover disabled" : "")
                    }
                    onClick={isFP ? undefined : () => onChangeMethod(T_SEAFREIGHT)}
                  >
                    Seafreight
                    <small className="text-success ml-2">
                      {seaFreightDisabled ? (
                        <Tooltip
                          tooltipText={`Instant orders are available for sea freight only for ${formatUnit(
                            SEA_MIN_ORDER_QUANTITY,
                            "kg"
                          )} or more.`}
                          tooltipClass={"custom-tooltip mb-8"}
                        >
                          <i className="fa fa-exclamation-circle text-muted opacity-04 ml-1" />
                        </Tooltip>
                      ) : (
                        <small className="text-success ml-2">{this.getAlternativePrice(T_SEAFREIGHT)[0]}</small>
                      )}
                    </small>
                  </label>
                  <label
                    style={{ opacity: isFP ? 0.7 : 1 }}
                    className={
                      "btn btn-light " + (method === T_AIRFREIGHT ? "active" : isFP ? "no-hover disabled" : "")
                    }
                    onClick={isFP ? undefined : () => onChangeMethod(T_AIRFREIGHT)}
                  >
                    Airfreight
                    {airFreightDisabled ? (
                      <Tooltip
                        tooltipText={`Instant orders are available for air freight only up to ${formatUnit(
                          AIR_MAX_ORDER_QUANTITY,
                          "kg"
                        )}.`}
                        tooltipClass={"custom-tooltip mb-8"}
                      >
                        <i className="fa fa-exclamation-circle text-muted opacity-04 ml-1" />
                      </Tooltip>
                    ) : (
                      <small className="text-success ml-2">{this.getAlternativePrice(T_AIRFREIGHT)[0]}</small>
                    )}
                  </label>
                  <label
                    style={{ opacity: isFP ? 0.7 : 1 }}
                    className={"btn btn-light " + (method === T_CONTRACT ? "active" : isFP ? "no-hover disabled" : "")}
                    onClick={isFP ? undefined : () => onChangeMethod(T_CONTRACT)}
                  >
                    Contract
                  </label>
                  <label
                    className={"btn btn-light " + (method === T_SPECIALREQUEST && "active")}
                    onClick={() => onChangeMethod(T_SPECIALREQUEST)}
                  >
                    Special Request
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        {amount <= 0 || suitableAmount ? (
          <div className="text-center mb-4 p-2 flex-grow-1">
            <div className="bg-light2 rounded p-6">
              <div className="d-flex flex-stack flex-grow-1 ">
                <div className=" text-center w-100">
                  <span className=" fw-bold fs-3">Invalid Amount</span>
                  <div className="fs-6 text-muted">
                    Your specified quantity does not correspond to the multiple of a packaging unit.
                    <br />
                    You may adjust your amount to{" "}
                    {suitableAmount?.lower && suitableAmount?.upper && articleState?.unit
                      ? formatUnit(suitableAmount?.lower, articleState.unit) +
                        " or " +
                        formatUnit(suitableAmount?.upper, articleState.unit)
                      : "a suitable one "}
                    or create a Request Order.
                    <br />
                    {suitableAmount?.lower && articleState?.unit ? (
                      <span
                        className="fw-bold text-white mx-3 text-hover-success"
                        onClick={() => onBlurAmount(suitableAmount?.lower)}
                      >
                        Decrease to {formatUnit(suitableAmount?.lower, articleState.unit)}
                      </span>
                    ) : null}
                    {suitableAmount?.upper && articleState?.unit ? (
                      <span
                        className="fw-bold text-white mx-3 text-hover-success"
                        onClick={() => onBlurAmount(suitableAmount?.upper)}
                      >
                        Increase to {formatUnit(suitableAmount?.upper, articleState.unit)}
                      </span>
                    ) : null}
                    <span
                      className="fw-bold text-white mx-3 text-hover-success cursor-pointer"
                      onClick={() => onChangeMethod(T_SPECIALREQUEST)}
                    >
                      Create Request Order
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {method === T_WAREHOUSE && !stockAvailable && !incomingOrderableStock && availableStock > 0 ? (
              <div className="text-center mb-4 p-2 flex-grow-1">
                <div className="bg-light2 rounded p-6">
                  <div className="d-flex flex-stack flex-grow-1 ">
                    <div className="text-center w-100">
                      <span className="fw-bold fs-3">Stock too low</span>
                      <div className="fs-6 text-muted">
                        <div className="text-muted">
                          Currently our stock is not enough to serve your quantity.
                          <br />
                          {amount > availableStock ? (
                            <>
                              <span className="text-success">
                                Right now {formatUnit(availableStock, articleState ? articleState.unit : "kg")} are
                                available.
                              </span>
                              <br />
                            </>
                          ) : priceInfo ? (
                            <span className="text-warning">
                              {formatUnit(availableStock, articleState ? articleState.unit : "kg")} available within a
                              few days
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              method === T_WAREHOUSE && (
                <div className="text-center mb-4 p-2  flex-grow-1">
                  <div className="  bg-light2 bg-danger rounded  p-6">
                    <div className="d-flex flex-stack flex-grow-1 ">
                      <div className=" fw-semibold text-center w-100">
                        {!noWarehousePrice && (useIncomingStock || availableStock > 0) ? (
                          useIncomingStock ? (
                            <>
                              <div className="text-white fw-bolder" style={{ fontSize: "2.2rem" }}>
                                {`${formatCurrency(warehouseTabPriceAsNumber, CUSTOMER_BASE_CURRENCY)}`}
                                <span className="text-muted fs-5 row justify-content-center">
                                  per {articleState ? articleState.unit : "kg"}, DDP
                                </span>
                              </div>
                              <span className="text-muted fw-bold mt-0 mb-2">
                                Total Price:{" "}
                                <span className="text-bold text-white fs-5">
                                  {formatCurrency(warehouseTabPriceAsNumber * amount, CUSTOMER_BASE_CURRENCY)}
                                </span>
                                <span className="text-muted">
                                  {incomingOrderableStock && !!incomingOrderableStock.availableAmount && (
                                    <div className="text-muted">
                                      Incoming amount:{" "}
                                      {formatUnit(
                                        incomingOrderableStock.availableAmount,
                                        articleState ? articleState.unit : "kg"
                                      )}
                                    </div>
                                  )}
                                  {availableStock > 0 && (
                                    <div className="text-muted">
                                      Instant available:{" "}
                                      {formatUnit(availableStock, articleState ? articleState.unit : "kg")}
                                    </div>
                                  )}
                                </span>
                                <div className={"text-warning"}>
                                  Earliest expected availability in CW
                                  {getCW(earliestDeliveryDate)}
                                </div>
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="text-white fw-bolder" style={{ fontSize: "2.2rem" }}>
                                {priceInfo ? (
                                  <>
                                    {formatCurrency(priceInfo.unitPrice, CUSTOMER_BASE_CURRENCY)}
                                    <span className="text-muted fs-5 row justify-content-center">
                                      per {articleState ? articleState.unit : "kg"}, DDP
                                    </span>
                                  </>
                                ) : (
                                  "Calculating..."
                                )}
                              </div>
                              <span className="text-muted fw-bold mt-0 mb-2">
                                Total Price:{" "}
                                <span className="text-bold text-white fs-5">
                                  {priceInfo
                                    ? formatCurrency(priceInfo.totalPrice, CUSTOMER_BASE_CURRENCY)
                                    : "Calculating..."}
                                </span>
                                <span className="text-muted">
                                  {availableStock > 0 && (
                                    <div className="text-muted">
                                      Total available:{" "}
                                      {formatUnit(availableStock, articleState ? articleState.unit : "kg")}
                                    </div>
                                  )}
                                </span>
                                <span className="text-muted">
                                  {earliestExpiry && (
                                    <div className="text-muted">Expiry from: {formatDate(earliestExpiry)}</div>
                                  )}
                                </span>
                                <div className={earliestDeliveryDate <= targetDate ? "text-success" : "text-danger"}>
                                  Delivery in a few days
                                </div>
                              </span>
                            </>
                          )
                        ) : loading ? (
                          <>
                            <div className=" fw-bold fs-3" style={{ height: "84.838px" }}>
                              <h2>&nbsp;</h2>
                              Calculating...
                              <span className="spinner-border spinner-border-sm align-middle ms-2 text-muted" />
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="fw-bold fs-3">Currently not available.</span>
                            <div className="fs-6 text-muted">
                              <div className="text-muted">
                                {incomingOrderableStock && incomingOrderableStock.availableAmount > 0 ? (
                                  <>
                                    {formatUnit(incomingOrderableStock.availableAmount, articleState?.unit || "kg")}{" "}
                                    available at the earliest from CW{getCW(earliestDeliveryDate)} for{" "}
                                    {formatCurrency(incomingOrderableStock.price, incomingOrderableStock.currency)}/
                                    {article?.unit}
                                    {availableStock > 0 && (
                                      <div className="text-success">
                                        Right now {formatUnit(availableStock, articleState?.unit || "kg")} are available
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  "This ordering method will be available again soon."
                                )}
                              </div>
                            </div>
                            <span
                              className="fw-bold text-white mx-3 text-hover-success"
                              onClick={() => onChangeMethod(T_SPECIALREQUEST)}
                            >
                              Create Request Order
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            {([T_SEAFREIGHT, T_AIRFREIGHT] as Array<CO_TYPES>).includes(method) && (
              <div className="text-center mb-4 p-2">
                <div className="bg-light2 rounded p-6">
                  <div className="d-flex flex-stack flex-grow-1 ">
                    <div className="text-center w-100">
                      {priceInfo ? (
                        noPrice && !supplier?.expired ? (
                          <>
                            <span className="fw-bold fs-3">Currently not available.</span>
                            <div className="fs-6 text-muted">
                              <div className="text-muted">This ordering method will be available again soon.</div>
                            </div>
                            <span
                              className="fw-bold text-white mx-3 text-hover-success"
                              onClick={() => onChangeMethod(T_SPECIALREQUEST)}
                            >
                              Create Request Order
                            </span>
                          </>
                        ) : (airFreightDisabled && method === T_AIRFREIGHT) ||
                          (seaFreightDisabled && method === T_SEAFREIGHT) ? (
                          <>
                            <span className="fw-bold fs-3">Amount on request.</span>
                            <div className="fs-6 text-muted">
                              <div className="text-muted">
                                {airFreightDisabled && method === T_AIRFREIGHT
                                  ? `Instant orders are available for air freight only up to ${formatUnit(
                                      AIR_MAX_ORDER_QUANTITY,
                                      "kg"
                                    )}.`
                                  : `Instant orders are available for sea freight only for ${formatUnit(
                                      SEA_MIN_ORDER_QUANTITY,
                                      "kg"
                                    )} or more.`}
                              </div>
                            </div>
                            <span
                              className="fw-bold text-white mx-3 text-hover-success"
                              onClick={() => onChangeMethod(T_SPECIALREQUEST)}
                            >
                              Create Request Order
                            </span>
                          </>
                        ) : (
                          <>
                            {!supplier?.expired && (
                              <div className="text-white fw-bolder" style={{ fontSize: "2.2rem" }}>
                                {formatCurrency(priceInfo.unitPrice, CUSTOMER_BASE_CURRENCY)}
                                <span className="text-muted fs-5 row justify-content-center">
                                  per {articleState ? articleState.unit : "kg"}, DDP
                                </span>
                              </div>
                            )}
                            <span className="text-muted fw-bold mt-0 mb-2">
                              {!supplier?.expired && (
                                <>
                                  Total Price:
                                  <span className="text-bold text-white fs-5 ml-1">
                                    {priceInfo
                                      ? noPrice
                                        ? "-"
                                        : formatCurrency(priceInfo.totalPrice, CUSTOMER_BASE_CURRENCY)
                                      : "Calculating..."}
                                  </span>
                                </>
                              )}
                              <div
                                className={
                                  priceInfo?.isFallback || supplier?.expired
                                    ? "text-danger"
                                    : earliestDeliveryDate <= targetDate
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {priceInfo?.isFallback || supplier?.expired
                                  ? "Price outdated"
                                  : !suitableAmount && `Delivery in ${getDeliveryTimeLabel(earliestDeliveryDate)}`}
                              </div>
                              {relevantSystemNotifications.length > 0 && (
                                <div className="mt-4 mx-6 p-2 bg-warning text-dark rounded fw-bolder row">
                                  <div className="col-1 align-self-center">
                                    <i
                                      className="fas fa-exclamation-triangle text-gray-800"
                                      style={{ fontSize: "2rem" }}
                                    />
                                  </div>
                                  <div className="col-11">
                                    {relevantSystemNotifications.map((rsN, idx) => (
                                      <React.Fragment key={rsN._id.toString()}>
                                        <div>{rsN.message}</div>
                                        {idx !== relevantSystemNotifications.length - 1 && (
                                          <div className="border-bottom-dark-gray my-1" />
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </span>
                          </>
                        )
                      ) : (
                        <>
                          <div className=" fw-bold fs-3 mb-3" style={{ height: "84.838px" }}>
                            <h2>&nbsp;</h2>
                            Calculating...
                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-muted" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {method === T_SPECIALREQUEST && (
              <>
                <div className="row flex-grow-1 mb-4">
                  <div className=" mt-2 mb-2">
                    <span className="text-warning">
                      For special needs you can make a request here. Our team will get back to you shortly after. The
                      request is used, for example, if you need to reach a certain target price, have a particularly
                      high purchase quantity, or you are unclear about something and need information to order.
                    </span>
                  </div>
                  <div className="d-flex flex-column p-2 col-12">
                    <div className="d-flex flex-column mb-2">
                      <label className="required fs-5 fw-bold mb-2">Message</label>
                      <div className="input-group">
                        <Textarea
                          value={note}
                          onChange={onChangeInput}
                          name={"note"}
                          className="form-control custom-form-control"
                          placeholder="For example, a specific target price, or you want a price for a very high purchase quantity for which we can renegotiate again with our suppliers, or queries about the raw material, anything you want to tell us."
                          rows={5}
                        />
                      </div>
                    </div>
                    <CustomerOrderFileUpload files={files} onSelectFiles={onFiles} onDeselectFiles={() => onFiles()} />
                  </div>
                </div>
              </>
            )}
            {method === T_CONTRACT && (
              <>
                <div className="row px-2">
                  <div className="col-6">
                    <label className="required fs-5 fw-bold mb-2">Validity Period</label>
                    <div>
                      <CustomSelect
                        options={CC_PERIOD_OPTIONS}
                        matchFormControl={true}
                        value={contractRequestData.period}
                        onChange={onChangeContractPeriod}
                      />
                    </div>
                    <div className="text-success float-right fs-8" style={{ height: "1rem" }}>
                      {`Earliest estimated start date: ${earliestDeliveryDate.toLocaleDateString()}`}
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">Minimum Quantity per Call</label>
                    <div className="input-group">
                      <Input
                        className="form-control custom-form-control"
                        type="number"
                        name="minimumCallQuantity"
                        min={0}
                        value={contractRequestData.minimumCallQuantity}
                        onChange={onChangeContractData}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {articleState?.unit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-4 p-2 flex-grow-1">
                  <div className="bg-light2 rounded p-6">
                    <div className="d-flex flex-stack flex-grow-1 ">
                      <div className="text-center w-100">
                        <span className="fw-bold fs-3">Price on request</span>
                        <div className="fs-6 text-muted">
                          <div className="text-muted">Our team will contact you shortly.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div>
          {!isAC && method !== T_SPECIALREQUEST && (priceInfo?.isFallback || supplier?.expired) ? (
            <ErrorOverlayButton
              errors={[]}
              className="btn btn-warning w-100 text-black "
              buttonText="Request Price Update"
              onClick={onRequestPriceUpdate}
            />
          ) : (
            <>
              <ErrorOverlayButton
                errors={isAC ? [] : errors}
                className={"btn w-100 " + (useIncomingStock ? "btn-warning" : "btn-success")}
                buttonText="Continue"
                disabled={loading}
                onClick={isAC ? this.handleClickOnBlur : onContinue}
              />
              <div className="text-center mt-2">
                <span className="text-muted">Next Step: Summary</span>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

interface CreateCustomerOrderSupplierSelectionProps {
  amount: number;
  article: ArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
  supplier?: SelectOption<Supplier>;
  labelDesign: SelectOption;
  onSelectSupplier: (e: SelectOption<Supplier>) => void;
  onSelectLabelDesign: (e: SelectOption) => void;
  method: CO_TYPES;
}

const CreateCustomerOrderSupplierSelection: React.FunctionComponent<CreateCustomerOrderSupplierSelectionProps> = ({
  amount,
  article,
  context,
  supplier,
  labelDesign,
  onSelectSupplier,
  onSelectLabelDesign,
  method,
}) => {
  const suppliers: Array<SelectOption<CustomerSupplier>> = useMemo(() => {
    const availableSuppliers: Array<SelectOption<CustomerSupplier>> = [];
    for (const supplier of article.suppliers) {
      // Exclude disabled suppliers and suppliers that do not contain valid prices with a moq less than the desired amount
      if (supplier.disabled || supplier.supplier.disabled || !supplier.prices.some((p) => p.minOQ <= amount)) continue;
      if (supplier.prices.every((p) => p.validUntil < new Date())) {
        availableSuppliers.push({
          value: supplier.supplier._id.toString(),
          label: `${supplier.supplier.name} (Prices Expired)`,
          object: supplier.supplier,
          expired: true,
        });
      } else {
        availableSuppliers.push({
          value: supplier.supplier._id.toString(),
          label: supplier.supplier.name,
          object: supplier.supplier,
        });
      }
    }
    return availableSuppliers;
  }, [article, amount]);

  let files: Array<{ label: string; file: UploadedFileExtended | BatchFile }> = useMemo(() => {
    // Get newest master spec or supplier spec
    const supplierFiles: Array<{ label: string; file: UploadedFileExtended }> = [];
    let specifications;
    if (!supplier) {
      specifications = _.orderBy(
        article.documents.filter(
          (d) => d.type === D_MASTERSPECIFICATION && (!d.validUntil || d.validUntil >= new Date())
        ),
        "date",
        "desc"
      );
    } else {
      specifications = _.orderBy(
        article.documents.filter(
          (d) =>
            d.supplier &&
            d.supplier._id.toString() === supplier.value &&
            d.type === D_SUPPLIERSPECIFICATION &&
            (!d.validUntil || d.validUntil >= new Date())
        ),
        "date",
        "desc"
      );
    }
    if (specifications.length > 0) supplierFiles.push({ label: "Specification", file: specifications[0] });

    return supplierFiles;
  }, [supplier]);

  const bFiles: Array<{ label: string; file: UploadedFileExtended | BatchFile }> = useMemo(() => {
    const batchFiles: Array<{ label: string; file: BatchFile }> = [];
    if (!supplier) return batchFiles;
    const batches = context.batch.filter(
      (b) => b.commodity._id.toString() === article._id.toString() && supplier.value === b.supplier
    );
    const coaFiles = _.orderBy(
      batches.map((b) => b.supplierCoA),
      "stockedDate",
      "desc"
    );
    if (coaFiles.length > 0) batchFiles.push({ label: "CoA", file: coaFiles[0] });
    return batchFiles;
  }, [context.batch, supplier]);

  files = files.concat(bFiles);

  return (
    <div className="row mb-4">
      <div className="col-12 col-md-8">
        <label className="align-items-center fs-6 fw-bold mb-2">
          <span>Supplier</span>
        </label>
        <CustomSelect
          options={suppliers}
          disabled={suppliers.length === 0 || method === T_WAREHOUSE}
          isClearable={true}
          onChange={onSelectSupplier}
          matchFormControl={true}
          placeholder={"Select a preferred supplier if desired..."}
          value={
            suppliers.length > 0 && method !== T_WAREHOUSE
              ? supplier
              : { value: "Rawbids", label: "Selected by RAWBIDS" }
          }
          additionalClasses="py-0"
        />
        <div className="mt-2">
          {files.map((f, idx) => (
            <a
              key={f.file._id.toString()}
              href={resolveFilePath(f.file.path)}
              target="_blank"
              rel="noopener noreferrer"
              className={"float-right " + (idx > 0 ? "mr-3" : "")}
            >
              <img alt="pdf" className="w-15px mr-2" src="/assets/media/svg/files/pdf.svg" />
              <span className="text-white fw-bold align-middle">{f.label}</span>
            </a>
          ))}
        </div>
      </div>
      <div className="col-12 col-md-4">
        <label className="align-items-center fs-6 fw-bold mb-2">
          <span>Shipping Label Design</span>
          <Tooltip
            tooltipText={
              <span className="text-white">
                In general we add a shipping label in Rawbids design to your delivery. If you want to have a clean label
                without the Rawbids logo you can select this here.
              </span>
            }
          >
            <span className="ml-1">
              <i className="fa fa-info-circle text-white" />
            </span>
          </Tooltip>
        </label>
        <CustomSelect
          options={C_LABEL_DESIGN_OPTIONS}
          onChange={onSelectLabelDesign}
          matchFormControl={true}
          value={labelDesign}
        />
      </div>
    </div>
  );
};

export default CreateCustomerOrderSettings;
