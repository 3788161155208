import _ from "lodash";
import React, { PureComponent } from "react";
import { formatCurrency, formatUnit, getDocFromCollection, pluralize, toAbsoluteUrl } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import {
  CO_TYPES,
  T_AIRFREIGHT,
  T_CONTRACT,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { SelectOption } from "../../common/CustomSelect";
import {
  getDeliveryTimeLabel,
  getPaymentTermsFromCompany,
  formatDateFromType,
} from "../../../utils/customerOrderUtils";
import { CustomerPriceInfo } from "../../../model/commonTypes";
import { Textarea } from "../../common/Textarea";
import { DateType, O_ORDERMETHODS } from "../../../utils/orderUtils";
import { D_MASTERSPECIFICATION } from "../../../utils/commodityUtils";
import userService from "../../../services/userService";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { resolveFilePath } from "../../../utils/fileUtils";
import CustomerOrderFileUpload from "./CustomerOrderFileUpload";
import { ContractRequestData } from "../../../utils/customerContractUtils";
import { MatchingIncomingOrderableStock } from "../../common/CustomTypes";
import { AddressSelectOption } from "../../../utils/addressUtils";
import { Supplier } from "../../../model/supplier.types";
import {
  getSupplierSpecs,
  getPackagingSizeString,
  formatArticleUnit,
  CustomerArticleExtended,
} from "../../../utils/productArticleUtils";
import { isCustomerFinishedProduct } from "../../../utils/finishedProductUtils";
import { CUSTOMER } from "../../../utils/userUtils";
import { PropertyType } from "../../../utils/propertyUtils";
import { UploadedFileExtended } from "../../../model/commodity.types";

interface CreateCustomerOrderOverviewProps {
  article: CustomerArticleExtended;
  priceInfo: CustomerPriceInfo | null;
  amount: number;
  shippingAddress: AddressSelectOption;
  reference: string;
  method: CO_TYPES;
  targetDate: Date;
  targetDateType?: DateType;
  earliestDeliveryDate: Date;
  errors: Array<string>;
  generating: boolean;
  tosAgreed: boolean;
  note: string;
  contractRequestData: ContractRequestData;
  files?: Array<File>;
  onFiles: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNote: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBack: () => void;
  onToggleToS: () => void;
  onConfirmOrder: () => void;
  context: DataContextCustomerType | DataContextAnonymousType;
  supplier?: SelectOption<Supplier>;
  labelDesign: SelectOption;
  incomingOrderableStock: MatchingIncomingOrderableStock | undefined;
}

interface CreateCustomerOrderOverviewState {}

class CreateCustomerOrderOverview extends PureComponent<
  CreateCustomerOrderOverviewProps,
  CreateCustomerOrderOverviewState
> {
  render() {
    const { method } = this.props;

    if (([T_SEAFREIGHT, T_AIRFREIGHT, T_WAREHOUSE] as Array<CO_TYPES>).includes(method))
      return <CustomerOrderOverview {...this.props} />;
    else if (method === T_SPECIALREQUEST) return <CustomerRequestOverview {...this.props} />;
    else if (method === T_CONTRACT) return <CustomerContractRequestOverview {...this.props} />;
    else return null;
  }
}

const CustomerOrderOverview: React.FunctionComponent<CreateCustomerOrderOverviewProps> = ({
  article,
  amount,
  shippingAddress,
  reference,
  method,
  priceInfo,
  targetDate,
  targetDateType,
  earliestDeliveryDate,
  errors,
  note,
  generating,
  tosAgreed,
  files,
  incomingOrderableStock,
  onFiles,
  onChangeNote,
  onBack,
  onToggleToS,
  onConfirmOrder,
  context,
  supplier,
  labelDesign,
}) => {
  const company = getDocFromCollection(context.company, userService.getCompany());
  let specifications: Array<UploadedFileExtended> = [];
  const masterSpecification = article.documents.find((d) => d.type === D_MASTERSPECIFICATION);
  if (supplier) {
    specifications = getSupplierSpecs(article, supplier.value);
  } else if (masterSpecification !== undefined) {
    specifications = [masterSpecification];
  }
  const mappedSpecs: Array<[key: string, value: OverviewEntryValue]> =
    specifications.map((s) => [`Specification `, { content: <FileWidget file={s} /> }]) || [];
  const priceCommodities =
    priceInfo && isFinite(priceInfo.totalPrice)
      ? priceInfo.totalPrice
      : incomingOrderableStock && method === T_WAREHOUSE
      ? incomingOrderableStock.price * amount
      : Infinity;
  const paymentTerms = company ? getPaymentTermsFromCompany(company, shippingAddress.address) : undefined;
  const isFP = isCustomerFinishedProduct(article, CUSTOMER);
  const composition = article.properties.find((p) => p.type === PropertyType.COMPOSITION);
  const overviewTableDefinition: Array<OverviewEntries> = [
    [
      "Commodity Information",
      [
        ["Title", { content: article.title.en }],
        ["Subtitle", { content: article.subtitle.en }],
        ["Article Number", { content: article.articleNo }],
        [isFP ? "Type" : "CAS Number", { content: isFP ? composition?.name.en ?? "-" : article.casNumber.join(" / ") }],
      ],
    ],
    [
      "Delivery Information",
      [
        ["Address", { content: shippingAddress.label }],
        [
          "Delivery",
          {
            content: `DDP, via ${
              O_ORDERMETHODS.find((ot) => ot.value === method)?.label || _.upperFirst(method)
            }, in ${getPackagingSizeString(article)}`,
          },
        ],
        [
          "ETA",
          {
            content: `Deliverable in approx. ${
              method === T_WAREHOUSE && incomingOrderableStock
                ? incomingOrderableStock.inWeeks + 1 + " weeks"
                : getDeliveryTimeLabel(earliestDeliveryDate)
            }. ${"Targeted for " + formatDateFromType(targetDate, targetDateType)}`,
            className: method === T_WAREHOUSE && incomingOrderableStock ? "text-warning" : "text-success",
          },
        ],
      ],
    ],
  ];
  if (mappedSpecs.length > 0) overviewTableDefinition.push(["Documents", mappedSpecs]);
  overviewTableDefinition.push([
    "Order Information",
    [
      ["Quantity", { content: formatUnit(amount, article.unit), className: "text-bold text-white fs-4" }],
      [
        "Price",
        {
          content: (
            <>
              <span className="text-bold text-white fs-4">
                {formatCurrency(priceCommodities, CUSTOMER_BASE_CURRENCY)}
              </span>
              <span className="text-bold text-muted fs-4 ml-1">
                ({formatCurrency(priceCommodities / amount, CUSTOMER_BASE_CURRENCY)}/{article.unit})
              </span>
            </>
          ),
        },
      ],
      ["Reference", { content: reference }],
      [
        "Payment",
        {
          content: paymentTerms
            ? paymentTerms.paymentTermConditions
              ? `${paymentTerms.paymentTerms} ${paymentTerms.paymentTermConditions}`
              : `${paymentTerms.paymentTerms} after the goods are received.`
            : `${pluralize(company?.paymentTarget || 14, "day")} after the goods are received.`,
        },
      ],
      ["Shipping Label Design", { content: labelDesign.value }],
    ],
  ]);

  return (
    <>
      <OverviewTable entries={overviewTableDefinition}>
        <div className="row flex-grow-1 mb-4">
          <div className="my-2">
            <div className="form-check form-check-sm form-check-custom form-check-solid mt-5" onClick={onToggleToS}>
              <input
                className="form-check-input position-static mr-2"
                checked={tosAgreed}
                type="checkbox"
                readOnly={true}
              />
              <label className="form-check-label text-warning ">
                I have verified all data carefully and understand that this order is binding and will be carried out as
                stated. Delivery dates are non-binding estimates and may vary due to external factors. By placing an
                order you agree to our{" "}
                <a href="/tos" target="_blank" rel="noopener noreferrer" className="text-white">
                  <b>
                    <u>terms of service</u>
                  </b>
                </a>
                .
              </label>
            </div>
          </div>
          <div className="d-flex flex-column p-2 col-12">
            <div className="d-flex flex-column mb-2">
              <label className="fs-5 fw-bold mb-2">Message</label>
              <div className="input-group">
                <Textarea
                  className="form-control custom-form-control"
                  name="note"
                  value={note}
                  onChange={onChangeNote}
                  placeholder={
                    "Tell us everything that is important to you. For example, specific delivery instructions or something you would like us to consider. You can also upload any document to the order." +
                    (method === T_WAREHOUSE
                      ? "\nIf you prefer to received stocked wares from a specific supplier please note this here. We will try to make it possible."
                      : "")
                  }
                  rows={5}
                />
              </div>
            </div>
            <CustomerOrderFileUpload files={files} onSelectFiles={onFiles} onDeselectFiles={() => onFiles()} />
          </div>
        </div>
      </OverviewTable>
      <div className="text-right">
        <button
          className={"btn btn-light btn-sm w-100 " + (generating ? "disabled" : "")}
          disabled={generating}
          onClick={generating ? undefined : onBack}
        >
          Back
        </button>
        <ErrorOverlayButton
          errors={!tosAgreed ? ["Please agree our terms of service."] : errors}
          className="btn btn-success btn-sm w-100 mt-2"
          buttonText={generating ? "Generating..." : "Place Binding Order"}
          saving={generating}
          onClick={onConfirmOrder}
        />
      </div>
      <div className="text-center mt-2">
        <span className="text-muted">
          Next step: Order is placed. We will regularly update you about the progress of your order.
        </span>
      </div>
    </>
  );
};

interface CustomerRequestOverviewProps
  extends Omit<
    CreateCustomerOrderOverviewProps,
    "method" | "priceInfo" | "tosAgreed" | "onChangeNote" | "onToggleToS" | "context" | "contractRequestData"
  > {}

const CustomerRequestOverview: React.FunctionComponent<CustomerRequestOverviewProps> = ({
  article,
  amount,
  shippingAddress,
  reference,
  targetDate,
  targetDateType,
  errors,
  note,
  files,
  generating,
  onBack,
  onConfirmOrder,
}) => {
  const isFP = isCustomerFinishedProduct(article, CUSTOMER);
  const composition = article.properties.find((p) => p.type === PropertyType.COMPOSITION);
  const overviewTableDefinition: Array<OverviewEntries> = [
    [
      "Commodity Information",
      [
        ["Title", { content: article.title.en }],
        ["Subtitle", { content: article.subtitle.en }],
        ["Article Number", { content: article.articleNo }],
        [isFP ? "Type" : "CAS Number", { content: isFP ? composition?.name.en ?? "-" : article.casNumber.join(" / ") }],
      ],
    ],
    [
      "Request Information",
      [
        [
          "Requested Quantity",
          { content: `${amount} ${formatArticleUnit(article.unit, article)}`, className: "text-bold text-white fs-4" },
        ],
        ["Address", { content: shippingAddress.label }],
        ["Reference", { content: reference }],
        [
          "Target Date",
          {
            content: `${"Targeted for " + formatDateFromType(targetDate, targetDateType)}`,
            className: "text-success",
          },
        ],
      ],
    ],
  ];
  return (
    <>
      <OverviewTable entries={overviewTableDefinition}>
        <>
          <div className="bg-light2 rounded p-5">
            <div className="d-flex align-items-center w-100">
              <div className="me-3">
                <div className="text-white fs-4 fw-bolder">Message</div>
              </div>
            </div>
            <div className="fs-6">
              <div className="py-5" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <span>{note}</span>
                <div className="d-block text-right ">
                  <span className="text-muted">Attachment: </span>
                  {files && files.length > 0 ? (
                    files.map((file) => (
                      <React.Fragment key={file.name + file.size + file.type}>
                        <span className="text-white">{file.name}</span>
                        <small className="text-muted ml-2">{formatUnit(file.size / 1000, "KB")}</small>
                      </React.Fragment>
                    ))
                  ) : (
                    <span className="text-white">-</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-4">
            <label className="form-check-label text-warning mb-2 mt-6">
              This is a non-binding request. We will review this request and contact you shortly after by email or
              phone. If all your requirements can be met, your non-binding request can be converted into an order. We
              will usually get back to you within 24 hours.
            </label>
          </div>
          <div className="text-right">
            <button
              className={"btn btn-light btn-sm w-100 " + (generating ? "disabled" : "")}
              disabled={generating}
              onClick={generating ? undefined : onBack}
            >
              Back
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-warning btn-sm w-100 mt-2"
              buttonText={generating ? "Generating..." : "Send Request"}
              saving={generating}
              onClick={onConfirmOrder}
            />
          </div>
          <div className="text-center mt-2">
            <span className="text-muted">Next step: Request is sent. We will get back to you soon.</span>
          </div>
        </>
      </OverviewTable>
    </>
  );
};

interface CustomerContractRequestOverviewProps
  extends Omit<
    CreateCustomerOrderOverviewProps,
    "method" | "priceInfo" | "targetDate" | "tosAgreed" | "onToggleToS" | "context"
  > {}

const CustomerContractRequestOverview: React.FunctionComponent<CustomerContractRequestOverviewProps> = ({
  article,
  amount,
  shippingAddress,
  reference,
  errors,
  note,
  generating,
  files,
  contractRequestData,
  onFiles,
  onChangeNote,
  onBack,
  onConfirmOrder,
}) => {
  const isFP = isCustomerFinishedProduct(article, CUSTOMER);
  const composition = article.properties.find((p) => p.type === PropertyType.COMPOSITION);
  const overviewTableDefinition: Array<OverviewEntries> = [
    [
      "Commodity Information",
      [
        ["Title", { content: article.title.en }],
        ["Subtitle", { content: article.subtitle.en }],
        ["Article Number", { content: article.articleNo }],
        [isFP ? "Type" : "CAS Number", { content: isFP ? composition?.name.en ?? "-" : article.casNumber.join(" / ") }],
      ],
    ],
    [
      "Request Information",
      [
        ["Requested Quantity", { content: formatUnit(amount, article.unit), className: "text-bold text-white fs-4" }],
        ["Address", { content: shippingAddress.label }],
        ["Reference", { content: reference }],
        [
          "Minimum Call Volume",
          {
            content: formatUnit(contractRequestData.minimumCallQuantity, article.unit),
            className: "text-bold text-white",
          },
        ],
        ["Validity Period", { content: contractRequestData.period.label, className: "text-success" }],
      ],
    ],
  ];
  return (
    <>
      <OverviewTable entries={overviewTableDefinition}>
        <>
          <div className="row flex-grow-1 mb-4">
            <div className="mt-5">
              <label className="form-check-label text-warning mb-2">
                This is a non-binding contract request. We will review this request and contact you shortly after by
                email or phone. Afterwards, your non-binding request can be converted into a contract. We will usually
                get back to you within 24 hours.
              </label>
            </div>
            <div className="d-flex flex-column p-2 col-12">
              <div className="d-flex flex-column mb-2">
                <label className="fs-5 fw-bold mb-2">Message</label>
                <div className="input-group">
                  <Textarea
                    className="form-control custom-form-control"
                    name="note"
                    value={note}
                    onChange={onChangeNote}
                    placeholder="Tell us everything that is important to you. For example, specific delivery instructions or something you would like us to consider. You can also upload any document to the order."
                    rows={5}
                  />
                </div>
              </div>
              <CustomerOrderFileUpload files={files} onSelectFiles={onFiles} onDeselectFiles={() => onFiles()} />
            </div>
          </div>
          <div className="text-right">
            <button
              className={"btn btn-light btn-sm w-100 " + (generating ? "disabled" : "")}
              disabled={generating}
              onClick={generating ? undefined : onBack}
            >
              Back
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-warning btn-sm w-100 mt-2"
              buttonText={generating ? "Generating..." : "Request Contract"}
              saving={generating}
              onClick={onConfirmOrder}
            />
          </div>
          <div className="text-center mt-2">
            <span className="text-muted">Next step: Request is sent. We will get back to you soon.</span>
          </div>
        </>
      </OverviewTable>
    </>
  );
};

interface OverviewEntryValue {
  content: string | JSX.Element;
  className?: string;
}

type OverviewEntries = [key: string, values: Array<[key: string, value: OverviewEntryValue]>];

interface CustomerOrderCommodityInformationProps {
  entries: Array<OverviewEntries>;
  children?: JSX.Element;
}

const OverviewTable: React.FunctionComponent<CustomerOrderCommodityInformationProps> = ({ entries, children }) => {
  return (
    <div className="flex-grow-1">
      <div className="bg-light2 rounded p-5 pb-0 mb-5">
        {entries.map(([key, values]) => (
          <React.Fragment key={key}>
            <div className="d-flex align-items-center w-100">
              <div className="me-3">
                <div className="text-white fs-4 fw-bolder">{key}</div>
              </div>
            </div>
            <div className="fs-6 ">
              <div className="d-flex flex-wrap py-5">
                <table className="table fw-bold gy-1">
                  <tbody>
                    {values.map(([vKey, val]) => (
                      <tr key={vKey}>
                        <td className="text-white w-25 min-w-125px">{vKey}</td>
                        <td className="text-muted w-75">
                          {val.className ? <span className={val.className}>{val.content}</span> : val.content}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      {children}
    </div>
  );
};

interface FileWidgetProps {
  file?: UploadedFileExtended;
}

const FileWidget: React.FunctionComponent<FileWidgetProps> = ({ file }) => {
  if (!file) return <div>-</div>;
  return (
    <a href={resolveFilePath(file.path)} target="_blank" rel="noopener noreferrer">
      <img alt="pdf" className="w-20px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
      {file.size && file.size > 0 ? (
        <span className="text-white align-middle">
          {file.path.replace(/.*\//, "")}
          {file.size && <small className="text-muted ml-2">{Math.round(file.size / 1024)} KB</small>}
        </span>
      ) : (
        <small className="text-muted ml-2">max. 500 KB</small>
      )}
    </a>
  );
};

export default CreateCustomerOrderOverview;
