import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { Input } from "../../common/Input";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { CompanyExtended } from "../../../model/company.types";
import { DataContextCustomerType } from "../../../context/dataContext";
import { getDocFromCollection } from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import PersonWidget from "../../common/PersonWidget";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { getUserName } from "../../../utils/userUtils";
import {
  C_LABEL_DESIGN_OPTIONS,
  LABEL_CLEAN_OPTION,
  LABEL_RAWBIDS_OPTION,
  LabelDesign,
  updateCompany,
} from "../../../utils/companyUtils";
import { getDefaultAddress, isEmptyAddress } from "../../../utils/addressUtils";
import { Address } from "../../../model/commonTypes";
import AddressInputGroup from "../../common/AddressInputGroup";
import { extendCompany } from "../../../utils/dataTransformationUtils";
import Tooltip from "../../common/Tooltip";

interface CompanySettingsProps {
  context: DataContextCustomerType;
}

interface CompanySettingsState {
  saving: boolean;
  company?: CompanyExtended;
}

class CompanySettings extends PureComponent<CompanySettingsProps, CompanySettingsState> {
  constructor(props: CompanySettingsProps) {
    super(props);
    this.state = {
      company: this.getCompany(props),
      saving: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<CompanySettingsProps>) {
    if (!_.isEqual(prevProps.context.company, this.props.context.company)) {
      this.setState({
        company: this.getCompany(this.props),
      });
    }
  }

  /**
   * Handles the change of a plain string value.
   * @param e Event that triggered the change
   */
  handleChangeStringValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.company);
    // @ts-ignore
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };

  handleClickSave = async () => {
    this.setState({ saving: true });
    const company = _.cloneDeep(this.state.company);
    try {
      if (company) {
        // Remove empty addresses if there
        company.address = company.address.filter((a) => !isEmptyAddress(a));
        const res = await updateCompany(_.pick(company, ["phone", "mail", "vat", "address"]), company._id);
        if (res && res.modifiedCount > 0) {
          toast.success("Company successfully updated");
        } else {
          toast.error("Error updating company");
        }
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleClickReset = () => {
    this.setState({
      company: this.getCompany(this.props),
    });
  };

  handleChangeAddress = (idx: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    // @ts-ignore
    company.address[idx][e.target.name as keyof Address] = e.target.value;
    this.setState({ company });
  };

  handleChangeAddressType = (idx: number, type: SelectOption) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    // @ts-ignore
    company.address[idx]["type"] = type.value;
    this.setState({ company });
  };

  handleChangeAddressCountry = (idx: number, country: SelectOption) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.address[idx].country = country.value;
    this.setState({ company });
  };

  handleSelectAddressContact = (idx: number, contact: SelectOption) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.address[idx].contactPerson = contact.value;
    this.setState({ company });
  };

  handleSelectAddressProperty = (idx: number, properties: Array<SelectOption>) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.address[idx].properties = properties.map((prop) => prop.value);
    this.setState({ company });
  };

  handleDeleteAddress = (idx: number) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.address.splice(idx, 1);
    this.setState({ company });
  };

  handleAddAddress = () => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.address.push(getDefaultAddress("DE"));
    this.setState({ company });
  };

  handleChangeOpeningTimes = (idx: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const company = _.cloneDeep(this.state.company);
    if (!company || !company.address[idx]) return;
    company.address[idx].openingHours = e.target.value;
    this.setState({ company });
  };

  handleChangeLabelDesign = (e: SelectOption) => {
    const company = _.cloneDeep(this.state.company);
    if (!company) return;
    company.cleanLabel = e.value === LabelDesign.CLEAN;
    this.setState({ company });
  };

  getCompany = (props: CompanySettingsProps): CompanyExtended | undefined => {
    const company = _.cloneDeep(getDocFromCollection(props.context.company, userService.getCompany()));
    if (company) return extendCompany(company, props.context);
  };

  validateData = () => {
    const { company } = this.state;
    const errors: Array<string> = [];
    if (!company) return errors;
    if (company.address.some((a) => isEmptyAddress(a, true))) errors.push("Fill or remove empty address");
    if (company.address.some((a) => !a.contactPerson)) errors.push("Select contact person for every address");
    if (!company.vat) errors.push("VAT is required");
    if (!company.phone) errors.push("Phone number is required");
    if (!company.mail) errors.push("Mail address is required");
    return errors;
  };

  render() {
    const { saving, company } = this.state;
    const { context } = this.props;
    if (!company) return <></>;
    const canEdit = company.primaryPerson && company.primaryPerson._id.toString() === userService.getUserId();

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body p-9">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Company Settings</span>
                </h3>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6">Name</label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <Input
                      className="form-control custom-form-control disabled"
                      type="text"
                      value={company.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6">Contact</label>
                  <div className="col-6 fv-row fv-plugins-icon-container mb-2">
                    <PersonWidget person={company.internalContact} />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6 required">Phone number</label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <Input
                      className={"form-control custom-form-control" + (canEdit ? "" : " disabled")}
                      type="text"
                      name="phone"
                      value={company.phone}
                      disabled={!canEdit}
                      onChange={canEdit ? this.handleChangeStringValue : undefined}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6 required">Mail address</label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <Input
                      className={"form-control custom-form-control" + (canEdit ? "" : " disabled")}
                      type="text"
                      name="mail"
                      value={company.mail}
                      disabled={!canEdit}
                      onChange={canEdit ? this.handleChangeStringValue : undefined}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6 required">VAT</label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <Input
                      className={"form-control custom-form-control" + (canEdit ? "" : " disabled")}
                      type="text"
                      name="vat"
                      value={company.vat}
                      disabled={!canEdit}
                      onChange={canEdit ? this.handleChangeStringValue : undefined}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6 required">
                    <span>Shipping Label Design</span>
                    <Tooltip
                      tooltipText={
                        <span className="text-white">
                          In general we add a shipping label in Rawbids design to your delivery. If you prefer clean
                          shipping labels you can adjust this here. You can still configure this differently for each
                          order.
                        </span>
                      }
                    >
                      <span className="ml-1">
                        <i className="fa fa-info-circle text-white" />
                      </span>
                    </Tooltip>
                  </label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <CustomSelect
                      matchFormControl={true}
                      value={company.cleanLabel ? LABEL_CLEAN_OPTION : LABEL_RAWBIDS_OPTION}
                      disabled={!canEdit}
                      onChange={canEdit ? this.handleChangeLabelDesign : undefined}
                      options={C_LABEL_DESIGN_OPTIONS}
                    />
                  </div>
                </div>
                {company.address.map((a, idx) => {
                  return (
                    <div className="row mb-6" key={a._id.toString()}>
                      {idx === 0 ? (
                        <label className="col-3 col-form-label fw-bold fs-6 required">Addresses</label>
                      ) : (
                        <div className="col-3" />
                      )}
                      <div className="col-6">
                        <AddressInputGroup
                          address={a}
                          persons={[company.primaryPerson, ...company.persons]}
                          disabled={!canEdit}
                          context={context}
                          onChangeAddress={(e) => this.handleChangeAddress(idx, e)}
                          onChangeAddressType={(e: SelectOption) => this.handleChangeAddressType(idx, e)}
                          onChangeAddressCountry={(e: SelectOption) => this.handleChangeAddressCountry(idx, e)}
                          onChangeAddressContact={(e: SelectOption) => this.handleSelectAddressContact(idx, e)}
                          onChangeAddressProperty={(e: Array<SelectOption>) => this.handleSelectAddressProperty(idx, e)}
                          onChangeOpeningHours={(e) => this.handleChangeOpeningTimes(idx, e)}
                        />
                      </div>
                      <div className="col-1 text-right">
                        {idx !== 0 && canEdit && (
                          <button className="btn btn-text btn-sm" onClick={() => this.handleDeleteAddress(idx)}>
                            <i className="fa fa-times text-danger pr-0" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="row mb-6">
                  <div className="col-9" />
                  <div className="col-1 text-right">
                    {canEdit && (
                      <button className="btn btn-text btn-sm" onClick={this.handleAddAddress}>
                        <i className="fa fa-plus pr-0" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-3 col-form-label fw-bold fs-6">Primary Person</label>
                  <div className="col-6 fv-row fv-plugins-icon-container">
                    <Input
                      className="form-control custom-form-control disabled"
                      type="text"
                      value={getUserName(company.primaryPerson)}
                      disabled
                    />
                  </div>
                </div>
                {company.persons.map((p, idx) => (
                  <div className="row mb-6" key={idx}>
                    {idx === 0 ? (
                      <label className="col-3 col-form-label fw-bold fs-6 pb-0">Other Persons</label>
                    ) : (
                      <div className="col-3" />
                    )}
                    <div className="col-6 fv-row fv-plugins-icon-container">
                      <Input
                        className="form-control custom-form-control disabled"
                        type="text"
                        value={getUserName(p)}
                        disabled
                      />
                    </div>
                  </div>
                ))}
              </div>
              {canEdit && (
                <div className="card-footer border-0 text-right">
                  <button
                    className={"btn btn-sm btn-outline btn-outline-light mr-2" + (saving ? " disabled" : "")}
                    onClick={saving ? undefined : this.handleClickReset}
                  >
                    Reset
                  </button>
                  <ErrorOverlayButton
                    errors={this.validateData()}
                    className={"btn btn-sm btn-outline btn-outline-light" + (saving ? " disabled" : "")}
                    buttonText="Save"
                    onClick={saving ? () => undefined : this.handleClickSave}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanySettings;
